import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharpenInfoState } from '../state/sharpen.state';

const getSharpenInfoState =
  createFeatureSelector<SharpenInfoState>('SharpenInfoState');

export const getSharpenInfo = createSelector(
  getSharpenInfoState,
  (state: SharpenInfoState) => state?.sharpenInfo,
);
