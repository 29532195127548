import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { NavigationStart, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Subject, takeUntil } from 'rxjs';
import {
  UPBsModalConfig,
  UPBsModalEvent,
  ModalSubmissionType,
  TagManagerService,
  PendoService,
  TagManagerConfig,
  PendoConfig,
  UPUtilityService,
  TranslationConfigService,
  Release,
  ProductNameConfig,
} from 'up';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'go-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isUserBack: boolean = false;
  isReaderPage: boolean = true;
  showSaveExitButton: boolean = true;
  showResult: boolean = false;
  responseType!: string;
  footerDisplay: boolean = true;
  mode: string = 'test';
  page: string = '';
  LEARNER: string = 'learner';
  userRole: string = 'learner';
  INSTRUCTOR: string = 'instructor';
  modalOption: string = '';
  viewResult: boolean = false;
  resultEaid!: string;
  modalType!: string;
  modalContent!: string;
  PRODUCT_SELECTION: string = 'product-selection';
  customAction!: string;
  hideExitButton: boolean = false;
  containerClass: string = 'container';
  bodyHeight!: number;
  private destroy$ = new Subject<boolean>();

  @ViewChild('exitModal') exitModal!: ElementRef;
  bsModalConfig: UPBsModalConfig = {
    title: '',
    submitType: ModalSubmissionType.CLOSE_MODAL,
    submitBtnText: '',
    closeBtnText: '',
    hideCloseBtn: false,
    hideFooter: false,
    featureType: '',
  };
  constructor(
    private tagManagerService: TagManagerService,
    private sharedService: SharedService,
    private pendoService: PendoService,
    private router: Router,
    private modalService: NgbModal,
    private upUtilityService: UPUtilityService,
    private translationConfigService: TranslationConfigService,
  ) {
    const tagManagerConfig: TagManagerConfig = {
      env: environment.tagManagerEnv,
      auth: environment.tagManagerAuth,
    };
    this.tagManagerService.addTag(tagManagerConfig);

    this.translationConfigService.setLocale(this.sharedService.getLmsLocale());

    this.sharedService.saveExitClickEvent
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.bsModalConfig.title = 'LEARNER.SAVE_EXIT.EXIT';
        this.bsModalConfig.submitBtnText = 'LEARNER.SAVE_EXIT.EXIT';
        this.bsModalConfig.submitType = ModalSubmissionType.SAVE_EXIT;
        this.bsModalConfig.featureType = 'learner-save-exit';
        this.modalService.open(this.exitModal, {
          size: 'md',
          keyboard: false,
          backdrop: 'static',
          centered: true,
        });
      });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.includes('error') || event.url.includes('unauthorized'))
          this.page = 'error';
      }
    });

    if (environment.enablePendo) {
      this.initializePendo();
    }

    this.pageDistributor(this.upUtilityService.getPathName() === '/');
  }

  initializePendo() {
    let mhProduct: string;
    const productNameConfig: ProductNameConfig = {
      author: this.sharedService.getProductAuthor(),
      title: this.sharedService.getProductTitle(),
      edition: this.sharedService.getProductEdition(),
      release: this.sharedService
        .getProductRelease()
        ?.find((release) => release.status === 'current'),
    };

    mhProduct = this.pendoService.getProductName(productNameConfig);
    const integrationType = this.sharedService.getNeoLaunch() ? 'LTIA' : 'Deep';
    const pendoConfig: PendoConfig = {
      VisitorConfig: {
        id: this.sharedService.getUserXid(),
        PersonXID: this.sharedService.getUserXid(),
        mh_environment: environment.envName,
        org: this.sharedService.getOrganizationXid(),
        mh_platform: this.sharedService.getPlatformName(),
        go_role: this.sharedService.getRole(),
        mh_courseDescriptor: this.sharedService.getISBN(),
        mh_subject: this.upUtilityService.capitalizeText(
          this.sharedService.getProductDisciplineName()!,
        ),
        mh_product: mhProduct,
        mh_lmsName: this.sharedService.getLmsName(),
        mh_integrationType: integrationType,
      },
      AccountConfig: {
        id: this.sharedService.getOrganizationXid(),
        name: this.sharedService.getOrganizationName(),
        mh_orgState: this.sharedService.getOrganizationState(),
        mh_orgCountry: this.sharedService.getOrganizationCountry(),
      },
    };

    this.pendoService.addPendo(pendoConfig);
  }

  pageDistributor(autoRedirection: boolean = true) {
    const role = this.sharedService.getRole();
    const isCourseCopy = this.sharedService.getIsCourseCopy();
    const customAction = this.sharedService.getLtiaAction();
    this.customAction = customAction;
    let redirectPath = '';
    if (isCourseCopy) {
      redirectPath = '/products/relinking/launch';
    } else if (role === this.INSTRUCTOR) {
      this.showSaveExitButton = false;
      this.showResult = true;
      this.page = this.instructorView(customAction);
      this.userRole = this.INSTRUCTOR;
      if (this.page === this.LEARNER) {
        redirectPath = '/ebook';
      } else {
        if (role === this.INSTRUCTOR && this.page == this.PRODUCT_SELECTION) {
          redirectPath = this.sharedService.getSectionPaired()
            ? '/products/launch'
            : '/products/select';
        } else if (role === this.INSTRUCTOR && this.page == this.INSTRUCTOR) {
          redirectPath = '/products/chapters';
        } else {
          redirectPath = '/error';
        }
      }
    } else {
      this.page = this.learnerView(customAction);
      this.userRole = this.LEARNER;
      redirectPath = '/ebook';
    }

    if (autoRedirection) {
      this.router.navigateByUrl(redirectPath);
    }
  }

  instructorView(customAction: string) {
    if (customAction === 'select') {
      if (this.sharedService.isGoLoadedInIframe()) {
        this.containerClass = 'container-fluid';
        return this.PRODUCT_SELECTION;
      } else {
        return this.INSTRUCTOR;
      }
    } else if (customAction === 'launch') {
      this.isUserBack = true;
      this.showResult = true;
      this.hideExitButton = false;
      this.showSaveExitButton = true;
      if (this.sharedService.isGoLoadedInIframe()) {
        this.containerClass = 'container-fluid';
        return this.PRODUCT_SELECTION;
      } else {
        this.isUserBack = false;
        return this.LEARNER;
      }
    } else {
      return 'error';
    }
  }

  learnerView(customAction: string) {
    return customAction === 'launch'
      ? this.sharedService.isGoLoadedInIframe()
        ? this.PRODUCT_SELECTION
        : this.LEARNER
      : 'error';
  }

  handleModalEvent(event: UPBsModalEvent) {
    switch (event.type) {
      case ModalSubmissionType.SAVE_EXIT:
        this.sharedService.onExit();
        break;
    }
    this.modalService.dismissAll();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
