import { SharpenInfo } from '../../interfaces/sharpen-info.interface';

export interface SharpenInfoState {
  sharpenInfo: SharpenInfo;
}

export const initialSharpenInfoState: SharpenInfoState = {
  sharpenInfo: {
    sharpenEnabled: false,
    sharpenSubscription: '',
  },
};
