import { SharpenActions, SharpenActionsUnion } from '../actions/sharpen.action';
import {
  initialSharpenInfoState,
  SharpenInfoState,
} from '../state/sharpen.state';

export const sharpenReducer = (
  state = initialSharpenInfoState,
  action: SharpenActionsUnion,
): SharpenInfoState => {
  switch (action.type) {
    case SharpenActions.LoadSharpenInfo: {
      return {
        ...state,
        sharpenInfo: action.payload.sharpenInfo,
      };
    }
    default:
      return state;
  }
};
