import {
  Component,
  ElementRef,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AttemptService } from 'src/app/shared/services/attempt.service';
import { Observable, take } from 'rxjs';
import { SharpenInfo } from '../../interfaces/sharpen-info.interface';
import { getSharpenInfo } from '../../store/selectors/sharpen.selector';
import { SharpenInfoState } from '../../store/state/sharpen.state';
import { Store } from '@ngrx/store';
import { ModalSubmissionType, UPBsModalConfig, UPBsModalEvent } from 'up';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'go-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  sharpenInfo$: Observable<SharpenInfo> = this.store.select(getSharpenInfo);
  chapterName: string | null = '';
  logo: string = '../../../../assets/GO_Logo.svg';
  userRole!: string;
  customAction!: string;
  isLockedCourse: boolean = false;
  isTemplateCourse: boolean = false;
  showQuestionsButton: boolean = false;
  showNewAttemptButton: boolean = false;
  showReadingButton: boolean = false;
  showResultsButton: boolean = false;
  showSubmitButton: boolean = false;
  page: string = 'READING';
  isButtonTextResumeAttempt: boolean = false;
  maxAttempts: number = 0;
  isButtonTextAttempts: boolean = false;
  isShowNavigation: boolean = true;
  isSharpenButtonVisible = signal(false);
  dueDateModalConfig!: UPBsModalConfig;
  isDueDatePassed = signal(false);
  @ViewChild('dueDateModal') dueDateModal!: ElementRef;

  constructor(
    private sharedService: SharedService,
    private router: Router,
    private attemptService: AttemptService,
    private store: Store<SharpenInfoState>,
    private modalService: NgbModal,
    private translate: TranslateService,
  ) {
    this.sharedService.courseAsTemplateEvent.subscribe(
      (isTemplateCourse: boolean) => {
        this.isTemplateCourse = isTemplateCourse;
      },
    );

    this.sharedService.learnerHeaderNavigationsEvent.subscribe(() => {
      this.learnerNavigations();
    });

    this.sharedService.getPageInfoEvent.subscribe((page: string) => {
      this.page = page;
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.includes('/unauthorized') ||
          event.url.includes('/error')
        ) {
          this.isShowNavigation = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.chapterName = this.sharedService.getAssignmentTitle();
    this.userRole = this.sharedService.getRole();
    this.customAction = this.sharedService.getLtiaAction();
    this.isLockedCourse = this.sharedService.getLockedCourse()!;
    this.maxAttempts = this.attemptService.getMaxAttempts();
    this.isDueDatePassed.set(this.sharedService.getDueDatePassed()!);
    this.learnerNavigations();
    this.getSharpenInfo();
    if (this.isDueDatePassed()) {
      this.createDueDateModalConfig();
    }
  }

  createDueDateModalConfig() {
    this.translate
      .get([
        'LEARNER.DUE_DATE_PASSED.TITLE',
        'INSTRUCTOR.SETTINGS_CONTENT_MODAL.BUTTON.CONTINUE',
        'INSTRUCTOR.SETTINGS_CONTENT_MODAL.BUTTON.CANCEL',
      ])
      .subscribe({
        next: (translatedText) => {
          this.dueDateModalConfig = {
            title: translatedText['LEARNER.DUE_DATE_PASSED.TITLE'],
            submitType: ModalSubmissionType.CONTINUE_TO_ASSIGNMENT,
            submitBtnText:
              translatedText[
                'INSTRUCTOR.SETTINGS_CONTENT_MODAL.BUTTON.CONTINUE'
              ],
            closeBtnText:
              translatedText['INSTRUCTOR.SETTINGS_CONTENT_MODAL.BUTTON.CANCEL'],
            featureType: 'due-date-past-modal',
            hideCloseBtn: false,
            hideFooter: false,
          };
        },
      });
  }

  getSharpenInfo() {
    this.sharpenInfo$.pipe(take(1)).subscribe((sharpenInfo: SharpenInfo) => {
      if (
        sharpenInfo &&
        sharpenInfo.sharpenEnabled &&
        sharpenInfo.sharpenSubscription === 'paid'
      ) {
        this.isSharpenButtonVisible.set(true);
      }
    });
  }

  learnerNavigations() {
    if (this.userRole === 'learner') {
      const attemptStatus = this.attemptService.getLatestAttemptStatus();
      const remainingAttempts = this.attemptService.getRemainingAttempts();
      const maxAttempts = this.attemptService.getMaxAttempts();
      const latestAttemptNo = this.attemptService.getLatestAttempts();
      this.showSubmitButton =
        !this.attemptService.getAttemptResultView() &&
        (attemptStatus === 'IN_PROGRESS' || latestAttemptNo === 0) &&
        this.page === 'QUESTION';

      if (this.sharedService.getAssignmentReadOnlyStatus()) {
        this.showQuestionsButton = false;
      } else if (maxAttempts === 1) {
        this.showQuestionsButton =
          (attemptStatus === 'IN_PROGRESS' || latestAttemptNo === 0) &&
          this.page === 'READING';
        this.showResultsButton =
          attemptStatus === 'SUBMITTED' && this.page === 'READING';
        this.showReadingButton = this.page === 'QUESTION';
      } else if (maxAttempts! > 1) {
        const attemptResultView = this.attemptService.getAttemptResultView();
        this.showQuestionsButton =
          (attemptStatus === 'IN_PROGRESS' ||
            latestAttemptNo === 0 ||
            attemptResultView) &&
          this.page !== 'QUESTION' &&
          this.page !== 'RESULT_SUMMARY';
        this.showResultsButton =
          remainingAttempts < maxAttempts! && this.page !== 'RESULT_SUMMARY';
        this.showNewAttemptButton =
          attemptStatus === 'SUBMITTED' &&
          remainingAttempts > 0 &&
          this.page !== 'QUESTION' &&
          this.page !== 'RESULT_SUMMARY';
        this.showReadingButton =
          this.page === 'QUESTION' || this.page === 'RESULT_SUMMARY';
        this.isButtonTextResumeAttempt =
          attemptStatus === 'IN_PROGRESS' && this.page === 'RESULT_SUMMARY';
        this.isButtonTextAttempts =
          maxAttempts > 1 && this.page !== 'RESULT_SUMMARY';
      }
    } else if (
      this.userRole === 'instructor' &&
      this.customAction === 'launch'
    ) {
      this.showReadingButton = this.page === 'QUESTION';
      if (this.sharedService.getAssignmentReadOnlyStatus()) {
        this.showQuestionsButton = false;
      } else if (this.page === 'READING') {
        this.showQuestionsButton = true;
      }
      const paginationWrapper = document.getElementById(
        'pagination-wrapper',
      ) as HTMLElement;
      if (paginationWrapper) {
        this.page === 'QUESTION'
          ? paginationWrapper.classList.remove('hide')
          : paginationWrapper.classList.add('hide');
      }
    }
  }

  backToQuestions() {
    const attemptStatus = this.attemptService.getLatestAttemptStatus();
    if (this.isDueDatePassed() && attemptStatus === 'NA') {
      this.modalService.open(this.dueDateModal, {
        size: 'md',
        keyboard: false,
        backdrop: 'static',
        centered: true,
      });
    } else this.navigateToQuestionView();
  }

  navigateToQuestionView() {
    if (this.page === 'RESULT_SUMMARY') {
      this.router.navigate(['/ebook']);
    }
    this.sharedService.toggleQuestionReaderView(true);
    this.page = 'QUESTION';
    this.learnerNavigations();
  }

  backToReading() {
    this.sharedService.emitLaunchEbookEvent(true);
    if (this.page === 'RESULT_SUMMARY') {
      this.router.navigate(['/ebook']);
    }
    this.sharedService.toggleQuestionReaderView(false);
    this.page = 'READING';
    this.learnerNavigations();
  }

  onSaveExit() {
    this.sharedService.onSaveExitClick();
  }

  onSubmit() {
    this.sharedService.onSubmitClick('resultPage');
  }

  startNewAttempt() {
    this.attemptService.setAttemptResultView(false);
    if (this.page === 'RESULT_SUMMARY') {
      this.attemptService.setNewAttemptStarted(true);
      this.sharedService.toggleQuestionReaderView(true);
      this.page = 'QUESTION';
      this.learnerNavigations();
      this.router.navigate(['/ebook']);
    } else {
      this.page = 'QUESTION';
      this.attemptService.onClickNewAttempt();
    }
  }

  showResults() {
    if (this.attemptService.getMaxAttempts() === 1) {
      this.navigateToQuestionView();
    } else {
      this.page = 'RESULT_SUMMARY';
      this.router.navigate(['/ebook/results']);
    }
  }

  handleModalEvent(event: UPBsModalEvent) {
    if (event.type === ModalSubmissionType.CONTINUE_TO_ASSIGNMENT) {
      this.navigateToQuestionView();
    }
    this.modalService.dismissAll();
  }
}
